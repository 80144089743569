"use strict";

// Imports
import { AppRegistry } from "../app.module";

// Header
export class Galleries {
	private nodes: any = {};

	//	Constructor
	constructor() {
		this.nodes.root = document.querySelector(".galleries");

		if (this.nodes.root) this.run();
	}

	// Run
	private run(): void {
		this.listeners();
	}

	// Listeners
	listeners(): void {
		console.log("Galleries Listeners")
	}
}