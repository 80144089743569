"use strict";

// Scroll to
export function ScrollTo( destination: string | number, duration: number, easing: string, callback?: Function ) {
	const easings = {
		linear(t) {
			return t;
	  	},
	  	easeInQuad(t) {
			return t * t;
	  	},
	  	easeOutQuad(t) {
			return t * (2 - t);
	 	},
	  	easeInOutQuad(t) {
			return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
	  	},
	  	easeInCubic(t) {
			return t * t * t;
	  	},
	  	easeOutCubic(t) {
			return (--t) * t * t + 1;
	  	},
	  	easeInOutCubic(t) {
			return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
	  	},
	 	easeInQuart(t) {
			return t * t * t * t;
	  	},
	  	easeOutQuart(t) {
			return 1 - (--t) * t * t * t;
	  	},
	  	easeInOutQuart(t) {
			return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * (--t) * t * t * t;
	  	},
	  	easeInQuint(t) {
			return t * t * t * t * t;
	  	},
	  	easeOutQuint(t) {
			return 1 + (--t) * t * t * t * t;
	  	},
	  	easeInOutQuint(t) {
			return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * (--t) * t * t * t * t;
	  	}
	};
  
	const start: number = window.pageYOffset;
	const startTime: number = "now" in window.performance ? performance.now() : new Date().getTime();
  
	const documentHeight: number = Math.max( document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight );
	const windowHeight: number = window.innerHeight || document.documentElement.clientHeight || document.getElementsByTagName( "body" )[0].clientHeight;
	const destinationOffset: number = typeof destination === "number" ? destination : document.querySelector( destination ).getBoundingClientRect().top;
	const destinationOffsetToScroll: number = Math.round( documentHeight - destinationOffset < windowHeight ? documentHeight - windowHeight : destinationOffset );
  
	if ( "requestAnimationFrame" in window === false ) {
		window.scroll( 0, destinationOffsetToScroll );

		if ( callback ) {
			callback();
		}
		  
	  	return;
	}
  
	function scroll() {
		const now: number = "now" in window.performance ? performance.now() : new Date().getTime();
	  	const time: number = Math.min(1, ((now - startTime) / duration));
	  	const timeFunction: number = easings[easing](time);
	  
		window.scroll( 0, Math.ceil((timeFunction * (destinationOffsetToScroll - start)) + start) );
  
	  	if ( window.pageYOffset === destinationOffsetToScroll ) {
			if (callback) {
		  		callback();
			}
		
			return;
	  	}
  
		requestAnimationFrame( scroll );
	}

	scroll();
}