"use strict";

// Imports
import { AppRegistry } from "../app.module";


// Attachments
export class Attachments {
	private nodes: any = {};

	//	Constructor
	constructor() {
		this.nodes.root = document.querySelector(".attachments");

		if (this.nodes.root && AppRegistry.isMobile) this.run();
	}

	// Run
	private run(): void {
		this.nodes.button = this.nodes.root.getElementsByTagName("button")[0];
		this.listeners();
	}

	// Listeners
	listeners(): void {
		this.nodes.button.addEventListener("click", () => {
			console.log("Show attachments list");
		});
	}
}