"use strict";

// Imports
import { Methods } from "./methods.module";

// Core
export abstract class Core {
	
	private static lastScrollPosition: number;
	private static mediaQueries: object = {
		"30em": "s",
		"48em": "m",
		"64em": "l",
		"80em": "xl",
		"90em": "xxl",
		"120em": "xxxl"
	};
	
	// Get document size
	static getDocumentSize(): object {
		return document.documentElement.getBoundingClientRect();
	}

	// Get window size
	static getWindowSize(): object {
		return {
			width: window.innerWidth,
			height: window.innerHeight
		}
	}

	// Get media query
	static getMediaQuery(): number {
		let mediaQuery: number;
		
		for ( let i in Core.mediaQueries ) {
			if ( window.matchMedia && window.matchMedia("screen and (min-width: " + i + ")").matches ) {
				mediaQuery = parseInt( i );
			}
		}
		
		if ( mediaQuery == null ) {
			mediaQuery = 0;
		}

		return mediaQuery;
	}

	// Get size
	static getSize(): string {
		const mediaQuery: string = `${Core.getMediaQuery()}em`;

		return ( parseInt(mediaQuery) === 0 ) ? "xs" : Core.mediaQueries[mediaQuery];
	}
	
	// Get orientation
	static getOrientation(): string {
		let orientation: string = "portrait";

		if ( window.matchMedia("(orientation: landscape)").matches ) {
			orientation = "landscape";
		}

		return orientation;
	}

	// Get scroll top
	static getScrollTop( element?: Element ): number {
		const top = ( element != null ) ? element.scrollTop : window.pageYOffset;

		return ( top < 0 ) ? 0 : top;
	}

	// Get scroll bottom
	static getScrollBottom( element?: Element, elementContent?: HTMLElement ): number {
		const height: number = ( elementContent ) ? elementContent.offsetHeight : Core.getDocumentSize()["height"];
		const bottom: number = ( height - Core.getWindowSize()["height"] ) - Core.getScrollTop( element );

		return ( bottom < 0 ) ? 0 : bottom;
	}

	// Get scroll perc
	static getScrollPerc( element?: Element, elementContent?: HTMLElement ): number {
		const height: number = ( !element ) ? Core.getDocumentSize()["height"] : elementContent.offsetHeight;

		return Core.getScrollTop( element ) / ( height - Core.getWindowSize()["height"] );
	}

	// Get scroll direction
	static getScrollDirection( element?: Element ): string {
		const direction: string = ( Core.getScrollTop( element ) > Core.lastScrollPosition ) ? "down" : "up";

		Core.lastScrollPosition = Core.getScrollTop( element );

		return direction;
	}

	// Get transition end
	static getTransitionEnd(): string {
		const el: HTMLElement = document.createElement( "fakeelement" );
		const transitions: object = {
		    "transition": "transitionend",
		    "OTransition": "oTransitionEnd",
			"MozTransition": "transitionend",
		    "WebkitTransition": "webkitTransitionEnd"
		};

		let t: string = "";

	    for ( t in transitions ) {
	        if ( el.style[t] !== undefined ) {
	            return transitions[t];
	        }
	    }
	}

	// Get animation end
	static getAnimationEnd(): string {
	    const el: HTMLElement = document.createElement( "fakeelement" );
		const animations: object = {
		    "animation":"animationend",
		    "OAnimation":"oAnimationEnd",
		    "MozAnimation":"animationend",
		    "WebkitAnimation":"webkitAnimationEnd"
		};

		let t: string = "";

	    for ( t in animations ) {
	        if ( el.style[t] !== undefined ) {
	            return animations[t];
	        }
	    }
	}

}