"use strict";

// Grid
export class Grid {
	private root: HTMLElement;

	//	Constructor
	constructor( root: HTMLElement ) {
		this.root = root;
		this.listeners();
	}

	// Listeners
	listeners(): void {
		this.root
			.querySelectorAll( "li" )
			.forEach( element => {
				let readMore: HTMLElement = ( <HTMLElement>element ).querySelector( "[class*='read-more']" );

				if ( readMore ) {
					let href: string = readMore.getAttribute( "href" );
					let media: HTMLElement = ( <HTMLElement>element ).querySelector( ".media" );

					media.classList.add( "cursor-pointer" );
					media.addEventListener( "click", () => {
						window.location.href = href;
					});
				}
			});
	}
}