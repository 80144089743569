"use strict";

// Imports
import { Core } from "../../@kilopix/core.module";
import { Fetcher } from "../common/Fetcher";

// HeroContacts
export class HeroContacts {
	private env: JSON;
	private nodes: object = {};
	private hasErrors: boolean = false;
	private isLoading: boolean = false;
	private alertTimeout: any = null;

	//	Constructor
	constructor() {
		this.nodes["root"] = document.querySelector(".hero-contacts");

		if (this.nodes["root"]) this.run();
	}

	// Run
	private run(): void {
		let envNode: HTMLElement = document.getElementById("env");

		if (envNode) {
			this.env = JSON.parse(envNode.innerHTML);
		}

		this.nodes["form"] = this.nodes["root"].querySelector("form");
		this.nodes["fields"] = this.nodes["form"].querySelectorAll("[name]");
		this.nodes["submit"] = this.nodes["form"].querySelector("button");

		this.listeners();
	}

	// Form
	private listeners(): void {
		this.nodes["submit"].addEventListener("click", (e) => {
			if (!this.isLoading && !this.hasErrors) {
				//
				let url: string = `${this.env["applicationPath"]}/services.aspx?cont=contact-form&type=JSON&language=${this.env["language"]}`;
				if (this.env["isPreview"]) url += "&preview=true";
				//
				this.isLoading = true;
				e.currentTarget.classList.add("loading");
				//
				let fetcher: Fetcher = new Fetcher(url,
					(data) => {
						//
						this.hideFormOnSuccess(data.message);
						//
						this.nodes["submit"].classList.remove("loading");
						this.isLoading = false;
					}, (status, jsonValue, message) => {
						//
						if (status === 400) this.renderFormErrors(jsonValue.errors);
						else this.renderAlert(message);
						//
						this.nodes["submit"].classList.remove("loading");
						this.isLoading = false;
					}
				);
				fetcher.formData = this.serialize();
				fetcher.send();
			}

			e.preventDefault();
		});

		this.nodes["fields"].forEach(element => {
			let eventType: string = (element.type != "checkbox") ? "keydown" : "change";

			if (element.type != "button") {
				element.addEventListener(eventType, () => {
					this.removeFieldErrors(element.parentNode);
				});
			}
		});
	}

	// Serialize
	private serialize(): FormData {
		//
		let formData: FormData = new FormData();
		//		
		let i: number = 0;
		for (; i < this.nodes["fields"].length; i++) {
			let field = this.nodes["fields"][i];

			if (field.type === "checkbox" || field.type === "radio") {
				formData.append(field.name, (field.checked == false) ? null : field.value);
			} else {
				formData.append(field.name, field.value);
			}
		}

		return formData;
	}

	//	Render form errors
	private renderFormErrors(errors: object): void {
		this.hasErrors = true;

		this.nodes["fields"].forEach(element => {
			let name: string = element.name;

			if (errors.hasOwnProperty(name)) {
				let parent: HTMLElement = element.parentNode;
				let errorsList: HTMLDivElement = document.createElement("div");

				parent.classList.add("has-errors");
				parent.appendChild(errorsList);

				errorsList.classList.add("errors");
				errorsList.innerHTML = this.getErrorsList(errors[name]);
				errorsList.style.height = `${errorsList.querySelector("ul").offsetHeight}px`;
			}
		});
	}

	// Render alert
	private renderAlert(message: string): void {
		this.removeAlert();

		let alert: HTMLDivElement = document.createElement("div");

		document.body.appendChild(alert);

		alert.id = "alert";
		alert.classList.add("show-alert");
		alert.innerHTML = message;

		this.alertTimeout = setTimeout(() => {
			alert.classList.replace("show-alert", "fade-out-full");
			alert.addEventListener(Core.getAnimationEnd(), (e) => {
				if (e["animationName"] === "fade-out-full") {
					this.removeAlert();
				}
			});
		}, 7000);
	}

	//	Remove field errors
	private removeFieldErrors(fieldParent: HTMLElement): void {
		if (fieldParent.classList.contains("has-errors")) {
			let errors: HTMLElement = fieldParent.querySelector(".errors");

			errors.style.height = "0px";
			errors.addEventListener(Core.getTransitionEnd(), () => {
				fieldParent.removeChild(errors);
				fieldParent.classList.remove("has-errors");

				if (!document.querySelectorAll(".errors").length) {
					this.hasErrors = false;
				}
			});
		}
	}

	// Hide form on success
	private hideFormOnSuccess(message: string): void {
		let success: HTMLDivElement = document.createElement("div");

		this.nodes["form"].appendChild(success);

		success.classList.add("success", "fade-in");
		success.innerHTML = `<p>${message}</p>`;
		success.addEventListener(Core.getAnimationEnd(), () => {
			this.nodes["fields"].forEach(element => {
				let type: string = element.type;

				if (type != "hidden" && type != "checkbox") {
					element.value = "";
				} else if (type === "checkbox") {
					element.checked = false;
				}
			});
		});
	}

	// Remove alert
	private removeAlert(): void {
		let alert = document.getElementById("alert");

		clearTimeout(this.alertTimeout);

		if (alert) document.body.removeChild(alert);
	}

	// Get errors list
	private getErrorsList(data: Array<string>): string {
		let errors: string = `<ul class="unstyled-list">`;
		let i: number = 0;

		for (; i < data.length; i++) {
			errors += `<li>${data[i]}</li>`;
		}

		errors += `</ul>`;

		return errors;
	}
}