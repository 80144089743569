"use strict";

//	Cookie banner

export class CookieBanner {

	//	Content set

	static consentSet(): void {
		const form = document.createElement( "form" );
		const input = document.createElement( "input" );

		form.style.height = "0"
		form.style.overflow = "hidden";
		form.action = document.location.href;
		form.method = "post";

		input.name = "cookiesConsent";
		input.value = "y";

		document.body.appendChild( form );

		form.appendChild( input );
		form.submit();
		
		//console.log( "I've got a cookie!" );
	}
	
	//	Resize spacer

	static resizeSpacer(): void {
		const cookieBanner: HTMLElement = document.getElementById( "script-cookie-banner" );

		if ( cookieBanner ) {
			const cookieBannerSpacer: HTMLElement = document.getElementById( "script-cookie-banner-spacer" );
			cookieBannerSpacer.style.height = `${cookieBanner.getBoundingClientRect().height.toString()}px`;
		}
	}

}