"use strict";

// Imports
import { AppRegistry } from "../app.module";

// Header
export class Header {
	private header: HTMLElement;
	private buttonMenu: HTMLElement;
	private hero: HTMLElement;

	//	Constructor
	constructor() {
		this.header = document.getElementById("header");
		this.buttonMenu = document.querySelector("#button-menu a");
		this.hero = document.getElementById("hero");

		this.listeners();
	}

	// Resize
	resize(): void {
		this.header.classList.remove("open");
	}

	// Scroll
	scroll(): void {
		//let triggerPoint: number = ( this.hero && AppRegistry.mediaQuery >= 48 ) ? (this.hero.offsetHeight - (128 * 3)) / 2 : 64;
		let triggerPoint: number = ( window.innerHeight <= 447 ) ? 64 : 128;

		if ( AppRegistry.scrollTop >= triggerPoint ) {
			this.header.classList.add("over");
		} else {
			this.header.classList.remove("over");
		}
	}

	// Listeners
	listeners(): void {
		this.buttonMenu.addEventListener("click", (e) => {
			this.header.classList.toggle("open")
			e.preventDefault();
		});
	}
}