"use strict";

// Imports
import { Core } from "../@kilopix/core.module";
import { Utils } from "../@kilopix/utils.module";
import { ScrollTo } from "../@kilopix/scrollTo.module";
import { Dev } from "../@kilopix/dev.module";
import { CookieBanner } from "./cookie-banner/cookie-banner.module";
import { Header } from "./header/header.module";
import { HeroSlideshow } from "./hero-slideshow/hero-slideshow.module";
import { HeroContent } from "./hero-content/hero-content.module";
import { HeroContacts } from "./hero-contacts/hero-contacts.module";
import { Galleries } from "./galleries/galleries.module";
import { Attachments } from "./attachments/attachments.module";
import { Grid } from "./common/grid.module";

// Declares
declare const window: any;

// App registry
export let AppRegistry = {
	mediaQuery: 0,
	size: "xs",
	orientation: "portrait",
	scrollTop: 0,
	scrollBottom: 0,
	scrollPerc: 0,
	scrollDirection: "down",
	isMobile: Utils.isMobile(),
	galleries: {}
};

// Enums
export enum Directions {
	Left = "LEFT",
	Right = "RIGHT"
}

// App Module (Singleton pattern)
class AppModule {
	private static instance: AppModule;

	private resizeTimeout: any;
	private header: Header;
	private heroSlideshow: HeroSlideshow;
	private heroContent: HeroContent;
	private heroContacts: HeroContacts;
	private galleries: Galleries;
	private attachments: Attachments;
	private html: HTMLElement;

	// Constructor
    private constructor() { }

	// Get instance
	static getInstance(): AppModule {
		if ( !AppModule.instance ) {
			const galleries: HTMLElement = document.querySelector("script#galleries");

			AppModule.instance = new AppModule();
			AppModule.instance.html = document.getElementsByTagName("html")[0];
			AppRegistry.galleries = (galleries) ? JSON.parse(galleries.innerHTML) : {};

			Dev.toggleLayoutGird();
		}
	
		return AppModule.instance;
	}

	// Run
	public run(): void {
		this.header = new Header();
		this.heroSlideshow = new HeroSlideshow( ".hero-slideshow" );
		this.heroContent = new HeroContent( ".hero-content .slides-wrap" );
		this.heroContacts = new HeroContacts();
		this.galleries = new Galleries();
		this.attachments = new Attachments();

		if ( document.querySelector("[class*='grid-']") ) {
			document.querySelectorAll("[class*='grid-']").forEach( element => {
				let item: HTMLElement = <HTMLElement>element;

				if ( !item.classList.contains("grid-details") ) {
					let grid: Grid = new Grid( item );
				} 
			});
		} 

		this.html.classList.replace( "no-js", "js" );
		this.html.classList.add( AppRegistry.isMobile ? "mobile" : "no-mobile" );

		window.addEventListener( "scroll", () => { this.scroll(); } );
		window.addEventListener( "resize", () => { this.resize(500); } );

		this.resize();
		this.listeners();
		
		/**
		 *	Rendo globale la funzione che gestisce le operazioni di accettazione
		 *	dei cookies. Passaggio obbligato visto che la chiamata alla funzione
		 *	avviene inline, nel tag html
		 */

		window.masterCookieConsentSet = CookieBanner.consentSet;
	}
	
	// Resize
	private resize( delay: number = 0 ): void {
		AppRegistry.mediaQuery = Core.getMediaQuery();
		AppRegistry.size = Core.getSize();
		AppRegistry.orientation = Core.getOrientation();

		AppModule.instance.html.classList.add("no-transition");
		
		clearTimeout( AppModule.instance.resizeTimeout );
		AppModule.instance.resizeTimeout = setTimeout( () => {
			CookieBanner.resizeSpacer();
			AppModule.instance.html.classList.remove("no-transition");
		}, delay );

		this.header.resize();
		this.scroll();
	}

	// Scroll
	private scroll(): void {
		AppRegistry.scrollTop = Core.getScrollTop();
		AppRegistry.scrollBottom = Core.getScrollBottom();
		AppRegistry.scrollPerc = Core.getScrollPerc();
		AppRegistry.scrollDirection = Core.getScrollDirection();

		this.header.scroll();
		this.heroSlideshow.scroll();
	}

	// Listeners
	private listeners(): void {
		const skipButton: HTMLElement = document.getElementById( "skip-button" );
		const topButton: HTMLElement = document.getElementById( "top-button" );

		if ( skipButton ) {
			skipButton.addEventListener( "click", ( e ) => {
				ScrollTo( "#content", 600, "easeOutQuad" );
				e.preventDefault();
			})
		}
		
		if ( topButton ) {
			topButton.addEventListener( "click", ( e ) => {
				ScrollTo( 0, 1200, "easeOutQuad" );
				e.preventDefault();
			})
		}
	}
}

export const App = AppModule.getInstance();