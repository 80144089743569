"use strict";

// Imports
import { Methods } from "./methods.module";

// Dev
export class Dev {

	// Toggle layout grid
	static toggleLayoutGird() {
		const grid: HTMLElement = document.createElement( "div" );
		const toggle: HTMLElement = document.createElement( "button" );

		grid.id = "dev-grid";
		grid.classList.add( "hide" );
		grid.innerHTML = `
			<span class="dev-col">01</span>
			<span class="dev-col">02</span>
			<span class="dev-col">03</span>
			<span class="dev-col">04</span>
			<span class="dev-col">05</span>
			<span class="dev-col">06</span>
			<span class="dev-col dev-colm">07</span>
			<span class="dev-col dev-colm">08</span>
			<span class="dev-col dev-coll">09</span>
			<span class="dev-col dev-coll">10</span>
			<span class="dev-col dev-coll">11</span>
			<span class="dev-col dev-coll">12</span>
			<span class="dev-col dev-colxl">13</span>
			<span class="dev-col dev-colxl">14</span>
			<span class="dev-col dev-colxl">15</span>
			<span class="dev-col dev-colxl">16</span>
		`;

		toggle.id = "dev-grid-toggle";
		toggle.innerHTML = "Toggle Grid";

		document.body.appendChild( grid );
		document.body.appendChild( toggle );

		document.getElementById( "dev-grid-toggle" ).addEventListener( "click", function( e ) {
			Methods.toggleClass( "#dev-grid", "hide" );
			e.preventDefault();
		});
	}

}