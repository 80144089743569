"use strict";

// Imports
import { AppRegistry, Directions } from "../app.module";
import { Hero } from "../common/hero.module";
import timeout from "../../lib/timeout";

// Header
export class HeroSlideshow extends Hero {
	private hasInteraction: boolean = false;
	private isStopped: boolean = false;
	private timer: object;

	//	Constructor
	constructor( root: string ) {
		super( root );

		if ( this.init() ) this.run();
	}

	// Scroll
	scroll(): void {
		if ( !this.nodes["root"] || this.srcSet.length <= 1 ) return;

		let y: number = this.nodes["root"].getBoundingClientRect()["height"] - AppRegistry.scrollTop;
		let triggerPoint: number = ( AppRegistry.mediaQuery < 48 ) ? 64 : 72;

		if ( y <= triggerPoint ) {
			if ( !this.isStopped ) {
				this.isStopped = true;
				
				if ( this.nodes["currentImage"] ) this.nodes["currentImage"].classList.add( "pause" );

				if ( timeout.exists(this.timer) ) {
					timeout.pause( this.timer );
				}
			}
		} else {
			if ( this.isStopped ) {
				this.isStopped = false;
				
				if ( this.nodes["currentImage"] )  this.nodes["currentImage"].classList.remove( "pause" );

				if ( timeout.exists(this.timer) ) {
					timeout.resume( this.timer );
				} else {
					this.startTimer();
				}
			}
		}
	}

	// Run
	private run(): void {
		if ( this.nodes["root"].classList.contains("interactive") ) {
			this.hasInteraction = true;

			this.drawNav();
			this.listeners();
			this.keyboardListeners();
		}
	}

	// Listeners
	private listeners(): void {
		this.nodes["buttonPrev"].addEventListener("click", ( e ) => {
			if ( !this.isLoading && !this.isStopped ) {
				e.currentTarget.classList.add( "loading" );

				this.direction = Directions.Left;
				this.setIndex();
				this.loadImage();
			}
		});

		this.nodes["buttonNext"].addEventListener("click", ( e ) => {
			if ( !this.isLoading && !this.isStopped ) {
				e.currentTarget.classList.add( "loading" );

				this.direction = Directions.Right;
				this.setIndex();
				this.loadImage();
			}
		});
	}

	// On loaded image
	protected onLoadedImage(): void {
		if ( this.srcSet.length > 1 ) {
			this.setNodes();
			this.showNewImage();
			
			if ( this.hasInteraction ) {
				this.nodes["buttonPrev"].classList.remove( "loading" );
				this.nodes["buttonNext"].classList.remove( "loading" );
			}
		}
	}

	// Show new image
	private showNewImage(): void {
		if ( this.hasInteraction ) {
			if ( this.nodes["oldFigure"] ) this.nodes["oldFigure"].classList.remove( "current" );

			this.nodes["currentFigure"].classList.remove( "out" );
			this.nodes["currentFigure"].classList.add( "fade-in", this.direction.toLowerCase(), "current" );
			this.nodes["currentImage"].classList.add( `sliding-${this.direction.toLowerCase()}` );
		} else {
			if ( this.nodes["oldFigure"] ) {
				this.nodes["oldFigure"].classList.remove( "current" );
				this.nodes["oldImage"].classList.add( "blur-out" );
			}

			this.nodes["currentFigure"].classList.remove( "out" );
			this.nodes["currentFigure"].classList.add( "fade-in", "current" );
			this.nodes["currentImage"].classList.add( "blur-in" );
		}

		if ( !this.isStopped && this.srcSet.length > 1 ) {
			this.startTimer();
		}
	}

	// Start timer
	private startTimer(): void {
		timeout.set( this.timer, () => {
			this.setIndex();
			this.loadImage();
		}, (AppRegistry.orientation === "landscape") ? 7000 : 12000 );
	}
	
	// Set index
	private setIndex(): void {
		if ( this.direction === Directions.Left ) {
			this.index = ( (this.index - 1) < 0 ) ? (this.srcSet.length - 1) : this.index - 1;
		} else {
			this.index = ( (this.index + 1) > (this.srcSet.length - 1) ) ? 0 : this.index + 1;
		}
	}
}