export class Fetcher {
	//
	public xhr: XMLHttpRequest;
	public formData: FormData = new FormData();
	private _onProgress: Function;

	//
	constructor(url: string, public onSuccess?: Function, public onError?: Function) {
		//
		this.xhr = new XMLHttpRequest();
		this.xhr.onreadystatechange = (e:Event) => {
			if (this.xhr.readyState == XMLHttpRequest.DONE) {
				if (this.xhr.status == 200 && this.xhr.responseText != "undefined") {
					// SUCCESS
					if (this.onSuccess !== undefined)
						this.onSuccess(JSON.parse(this.xhr.responseText));
				}
				else {
					// ERROR
					if (this.onError !== undefined) {
						let jsonValue: any = undefined;
						let message: string = undefined;
						try {
							jsonValue = JSON.parse(this.xhr.responseText);
							message = jsonValue.message;
						}
						catch(e) {}
						this.onError(this.xhr.status, jsonValue, message);
					}
				}
			}
		};
		this.xhr.upload.addEventListener("progress", (e) => {
			let percentage: number = Math.ceil((e.loaded * 100) / e.total);
			if (this._onProgress !== undefined) this._onProgress(percentage, e);
		});

		//
		this.xhr.open("POST", url, true);
	}

	public abort(): Fetcher {
		this.xhr.abort();
		return this;
	}

	public onProgress(onProgress: Function): Fetcher {
		this._onProgress = onProgress;
		return this;
	}

	public append(key: string, value: FormDataEntryValue): Fetcher {
		if (key === undefined || value === undefined) return this;
		//
		this.formData.append(key, value);
		return this;
	}

	public delete(key: string): Fetcher {
		this.formData.delete(key);
		return this;
	}

	public get(key: string): FormDataEntryValue {
		return this.formData.get(key);
	}

	public getAll(key: string): FormDataEntryValue[] {
		return this.formData.getAll(key);
	}

	public has(key: string): boolean {
		return this.formData.has(key);
	}

	public send(): Fetcher {
		if (this.formData === undefined) this.xhr.send();
		else this.xhr.send(this.formData);
		return this;
	}
}