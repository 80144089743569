"use strict";

// Methods
export abstract class Methods {

    // Select
    static select( query: string ): Array<Element> {
        if ( typeof query === "string" ) {

        } else {
            
        }

        const lastElement: string = query.split( " " ).pop();

        let elements: Array<Element> | NodeList;

        if ( lastElement.charAt(0) === "#" ) {
            elements = [document.getElementById( lastElement.replace("#", "") )];
        } else {
            if ( lastElement.match(/[:][^:not]/g) ) {
                elements = [document.querySelector( query )];
            } else {
                elements = document.querySelectorAll( query );
            }
        }

        return Object.keys( elements ).map( i => elements[i] ).filter( i => i == null ? false : true );
    }

    // Add class
    static addClass( query: string, className: string ): void {
        const selection: Array<Element> = Methods.select( query );

        if ( !selection.length ) return;

        selection.forEach( (element: Element) => {
            const classString: string = ( element.classList ) ? className : element.className;

            if ( element.classList ) {
                const classes: Array<string> = classString.split( " " );
                for ( let i: number = 0; i < classes.length; i++ ) {
                    element.classList.add( classes[i] );
                }
            } else {
                element.className += `${(classString == "") ? "" : " "}${className}`;
            }
        });
    }

    // Remove class
    static removeClass( query: string, className: string ): void {
        const selection: Array<Element> = Methods.select( query );

        if ( !selection.length ) return;

        const classes: Array<string> = className.split( " " );

        selection.forEach( (element: Element) => {
            if ( element.classList ) {
                for ( let i: number = 0; i < classes.length; i++ ) {
                    element.classList.remove( classes[i] );
                }
            } else {
                element.className =  element.className.replace( new RegExp(`(^|\\b)${classes.join('|')}(\\b|$)`, "gi"), " " ).trim();
            }
        });
    }

    // Toggle class
    static toggleClass( query: string, className: string, force?: boolean ): void {
        const selection: Array<Element> = Methods.select( query );

        if ( !selection.length ) return;

        const classes: Array<string> = className.split( " " );

        selection.forEach( (element: Element) => {
            const classString: string = element.className;

            if ( element.classList ) {
                classes.forEach( (cl: string) => {
                    element.classList.toggle( cl, (force != null) ? force : undefined );
                });
            } else {
                classes.forEach( (cl: string) => {
                    if ( classString.indexOf(cl) < 0 ) {
                        element.className += `${(classString == "") ? "" : " "}${cl}`;
                    } else {
                        element.className = element.className.replace( cl, " " ).trim();
                    }
                });
            }
        });
    }

    // Replace class
    static replaceClass( query: string, oldClassName: string, newClassName: string ): void {
        const selection: Array<Element> = Methods.select( query );

        if ( !selection.length ) return;

        const oldClasses: Array<string> = oldClassName.split( " " );
        const newClasses: Array<string> = newClassName.split( " " );

        selection.forEach( (element: Element) => {
            if ( element.classList ) {
                oldClasses.forEach( (oldClass: string, index: number) => {
                    element.classList.replace( oldClass, newClasses[index] );
                });
            } else {
                oldClasses.forEach( (oldClass: string, index: number) => {
                    element.className = element.className.replace( `${oldClass}`, `${newClasses[index]}` ).trim();
                });
            }
        });
    }

    // Has class
    static hasClass( query: string, className: string ): boolean {
        const element: Element = Methods.select( query )[0];

        if ( element == null ) return false;

        if ( element.classList ) {
            return element.classList.contains( className );
		} else {
            return new RegExp( `(^| )${className}( |$)`, "gi").test( element.className );
        }

        return false;
    }

    // Css (WIP)
    static css( query: string, style: object ): void {

    }
 
}