"use strict";

// Imports
import { Directions } from "../app.module";
import { Hero } from "../common/hero.module";

// Header
export class HeroContent extends Hero {
	//	Constructor
	constructor( root: string ) {
		super( root );

		if ( this.init() ) this.run();
	}

	// Run
	private run(): void {
		this.drawNav();
		this.listeners();
		this.keyboardListeners();
	}

	// Listeners
	private listeners(): void {
		this.nodes["buttonPrev"].addEventListener("click", ( e ) => {
			if ( !this.isLoading && (this.index - 1) >= 0 ) {
				e.currentTarget.classList.add( "loading" );

				this.direction = Directions.Left;
				this.index--;
				this.loadImage();
			}
		});

		this.nodes["buttonNext"].addEventListener("click", ( e ) => {
			if ( !this.isLoading && (this.index + 1) <= (this.srcSet.length - 1) ) {
				e.currentTarget.classList.add( "loading" );

				this.direction = Directions.Right;
				this.index++;
				this.loadImage();
			}
		});
	}

	// On loaded image
	protected onLoadedImage(): void {
		if ( this.srcSet.length > 1 ) {
			this.setNodes();
			this.showNewImage();
			this.updateNav();
			
			this.nodes["buttonPrev"].classList.remove( "loading" );
			this.nodes["buttonNext"].classList.remove( "loading" );
		}
	}

	// Show new image
	private showNewImage(): void {
		if ( this.nodes["oldFigure"] ) {
			this.nodes["oldFigure"].classList.remove( "current", "no-time-animation" );
			this.nodes["oldFigure"].classList.add( "fade-out" );
		}

		this.nodes["currentFigure"].classList.remove( "out" );
		this.nodes["currentFigure"].classList.add( `slide-in-${this.direction.toLowerCase()}`, "current" );
	}

	// Set index
	private updateNav(): void {
		if ( this.index === 0 ) {
			this.nodes["buttonPrev"].classList.add("off");
			this.nodes["buttonNext"].classList.remove("off");
		} else if ( this.index === this.srcSet.length - 1 ) {
			this.nodes["buttonPrev"].classList.remove("off");
			this.nodes["buttonNext"].classList.add("off");
		} else {
			this.nodes["buttonPrev"].classList.remove("off");
			this.nodes["buttonNext"].classList.remove("off");
		}
	}
}