"use strict";

// Imports
import { Core } from "../../@kilopix/core.module";
import { Directions } from "../app.module";

// Header
export abstract class Hero {
	protected nodes: object = {};
	protected srcSet: Array<string> = [];
	protected direction: string = Directions.Right;
	protected isLoading: boolean;
	protected index: number = -1;

	//	Constructor
	constructor( root: string ) {
		this.nodes["root"] = document.querySelector( root );
	}

	// Init
	protected init(): boolean {
		let images: NodeList | [] = ( this.nodes["root"] ) ? this.nodes["root"].querySelectorAll( "img" ) : [];

		if ( images.length ) {
			this.nodes["figures"] = this.nodes["root"].querySelectorAll( "figure" );

			if ( images.length === 1 ) {
				//this.srcSet.push( this.nodes["root"].querySelector("img").getAttribute("src") );
				//this.loadImage();

				return false;
			} else {
				images.forEach( element => {
					this.srcSet.push( (<HTMLImageElement>element).getAttribute("src") );
				});

				this.nodes["figures"].forEach( (element, index) => {
					let figure = <HTMLElement>element;

					figure.classList.add( (index === 0) ? "no-time-animation" : "out" );
					figure.addEventListener( Core.getAnimationEnd(), (e) => {
						if ( e["animationName"] === "fade-in" || e["animationName"].includes("slide-in-") ) {
							this.isLoading = false;
							this.resetOlder();
						}
					});
				});

				this.loadImage();

				return true;
			}
		} else {
			return false;
		}
	}

	// Load image
	protected loadImage(): void {
		let image: HTMLImageElement = new Image();

		this.isLoading = true;

		if ( this.index < 0 ) {
			this.index = 0;
		}

		image.onload = () => {
			this.onLoadedImage();
		}

		image.src = this.getImageSrc();
	}

	// On loaded image
	protected onLoadedImage(): void {}

	// Draw nav
	protected drawNav(): void {
		const nav: HTMLDivElement = document.createElement( "div" );

		nav.classList.add( "slides-nav" );
		nav.innerHTML = `
			<button class="button-prev off"><span class="sr-only">Prev</span></button>
			<button class="button-next"><span class="sr-only">Next</span></button>
		`;
		
		this.nodes["buttonPrev"] = nav.querySelector( ".button-prev" );
		this.nodes["buttonNext"] = nav.querySelector( ".button-next" );

		this.nodes["root"].appendChild(nav);
	}

	// Keyboard listeners
	protected keyboardListeners(): void {
		document.addEventListener("keydown", (e) => {
			if ( e.keyCode === 39 ) {
				this.nodes["buttonNext"].click();
			} else if ( e.keyCode === 37 ) {
				this.nodes["buttonPrev"].click();
			}
		});
	}

	// Set nodes
	protected setNodes(): void {
		this.nodes["oldFigure"] = this.nodes["root"].querySelector( ".current" );
		this.nodes["oldImage"] = this.nodes["root"].querySelector( ".current img" );
		this.nodes["currentFigure"] = <HTMLElement>this.nodes["figures"][this.index];
		this.nodes["currentImage"] = this.nodes["currentFigure"].querySelector( "img" );
	}

	// Reset older
	private resetOlder(): void {
		this.nodes["figures"].forEach( (element, index) => {
			let figure: HTMLElement = <HTMLElement>element;
			let image: HTMLImageElement = figure.querySelector( "img" );

			if ( index != this.index ) {
				figure.removeAttribute( "class" );
				figure.classList.add( "out" );

				image.removeAttribute( "class" );
				image.removeAttribute( "style" );
			}
		});
	}

	// Get image src
	private getImageSrc(): string {
		let src: string = this.srcSet[this.index];
		let wrapRect: ClientRect = this.nodes["root"].getBoundingClientRect();
		let width: string = Math.round(wrapRect.width * 1.1).toString();
		let height: string = Math.round(wrapRect.height * 1.1).toString();

		return src.replace(/\/(\d+)x(\d+)\//, `/${width}x${height}/`);
	}
}