"use strict";

// Utils
export abstract class Utils {

    // Is mobile
    static isMobile(): boolean {
		if ( /Mobi/i.test(navigator.userAgent) || /Android/i.test(navigator.userAgent) ) {
			return true;
		}

		return false;
	}

}